<template>
	<AAnimationSkeleton>
		<ASkeleton width="330px" height="310px" />
	</AAnimationSkeleton>
</template>

<style scoped lang="scss">
.banner-skeleton {
	border-radius: 20px;
}
</style>
